import React, { useState, useEffect } from "react";
import "./ModalText.css";
import Close from "../../assets/images/close.png";
import useLanguage from '../../hooks/useLanguage';
import { Trans } from 'react-i18next';
import ModalImgENG from './../../assets/images/modal-minepet-eng.png'
import ModalImgKOR from './../../assets/images/modal-minepet-kor.png'
import ModalImgMobile from './../../assets/images/modal-minepet-eng-mobile.png'
import ModalImgMobileKOR from './../../assets/images/modal-minepet-kor-mobile.png'
import { Mobile, PC } from '../../hooks/useMedia';

const ModalText = ({onClose, onClick}) => {
    
    const { t, langState } = useLanguage();
    return (
        <>
        <div className='modal-background'></div>
        <div className='modal-wrap-up'>
            <img src={Close} alt='close' onClick={onClose} className='modal-close-icon'/> 
            <div className='title-wrap'>

            <p className='modal-top-title'>
                <Trans i18nKey="modal-title" components={{ p: <p /> }} />
            </p>
            </div>
            <div className='content-wraper'>

            <p className='modal-content'>
                <Trans i18nKey="modal-content" components={{ p: <p /> }} />
            </p>
            </div>
        </div>
        </>
    );
};

export default ModalText;